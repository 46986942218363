// export const trials = {
//     "IgA-Nephropathy": 'https://drive.google.com/file/d/1u11ohqgQjmbWRRwRacyq9b0Gr0e0f2Da/preview',
//     "Lupus-Nephritis": 'https://drive.google.com/file/d/1VyLl0fwmfw6JPIcXQ4nKFbrE7Z3TVyoP/preview',
//     "Hypertension": 'https://drive.google.com/file/d/1LOQFYxDCgUlfaTpOUPkcBf9CiF6tBZ-A/preview',
//     "Genetics": 'https://drive.google.com/file/d/1YPjoSCYWQczi-FlYopG6j40eV5jF7qTi/preview',
//     "Dialysis": 'https://drive.google.com/file/d/1kA1pBo7WwlPLriY6VHpaRXAe2q7E7gq3/preview',
//     "Chronic-Kidney-Disease": 'https://drive.google.com/file/d/1B93UfpNphACUUvWL6Z7285clzRB06rBB/preview',
//     "ANCA-Vasculitis": 'https://drive.google.com/file/d/1E50HKuO-2tMYpqLpaQ0VGTtzA079hUtx/preview',
// }

export const trials = {
    "IgA-Nephropathy": 'https://drive.google.com/file/d/1wjNMB5EgRLiIWdcW8toihv8kyUf-9Saa/preview',
    "Lupus-Nephritis": 'https://drive.google.com/file/d/12NxTdKAw0jeM_qySKUd_n87XCQl_D-EP/preview',
    "Hypertension": 'https://docs.google.com/document/d/1md_LoMYoE3zlFpWyoDcx50HSMuAhy_0C0aVUTvTKGMI/preview',
    "Genetics": 'https://drive.google.com/file/d/1KEuKmYAZRMm3QQiMMnb7yOcQzyrwYbk9/preview',
    "Dialysis": 'https://docs.google.com/document/d/1md_LoMYoE3zlFpWyoDcx50HSMuAhy_0C0aVUTvTKGMI/preview',
    "Chronic-Kidney-Disease": 'https://docs.google.com/document/d/1md_LoMYoE3zlFpWyoDcx50HSMuAhy_0C0aVUTvTKGMI/preview',
    "ANCA-Vasculitis": 'https://docs.google.com/document/d/1md_LoMYoE3zlFpWyoDcx50HSMuAhy_0C0aVUTvTKGMI/preview',
    "Diabetes": 'https://docs.google.com/document/d/1md_LoMYoE3zlFpWyoDcx50HSMuAhy_0C0aVUTvTKGMI/preview',
    "Other": 'https://docs.google.com/document/d/1md_LoMYoE3zlFpWyoDcx50HSMuAhy_0C0aVUTvTKGMI/preview',
}